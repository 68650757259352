'use strict'

###*
 # @ngdoc object
 # @name mundoAuthentication.controller:UserAccountCtrl

 # @description

###
class UserAccountCtrl
  ### @ngInject ###
  constructor: (
    $rootScope
    MyUser
    timezones
  ) ->
    @ctrlName = 'UserAccountCtrl'
    @user = $rootScope.user

    @timezones = timezones
    @locales =
      en: 'English'
      nl: 'Nederlands'
      fr: 'Français'

    @profileErrorMessage = null
    @profileMessage = null
    @profileEntity =
      username: null
      email: null
      timezone: null
      locale: null

    @passwordErrorMessage = null
    @passwordMessage = null
    @passwordEntity =
      oldPassword: null
      plainPassword: null
      plainPasswordVerification: null

    @reloadInfo = (trigger = false) ->
      if trigger
        $rootScope.$broadcast 'mundo:user:reload'
        return

      if not @user
        return

      @profileEntity.username = @user.username
      @profileEntity.email = @user.email
      @profileEntity.timezone = @user.timezone
      @profileEntity.locale = @user.locale

      @passwordEntity.oldPassword = null
      @passwordEntity.plainPassword = null
      @passwordEntity.plainPasswordVerification = null

    @reloadInfo()

    $rootScope
      .$on 'mundo:authentication:success', (event, data) =>
        @user = $rootScope.user
        @reloadInfo()

    @updateProfile = () =>
      @profileErrorMessage = null
      @profileMessage = null

      MyUser.one()
        .patch @profileEntity
        .then (result) =>
          @profileErrorMessage = null
          @profileMessage = 'app.messages.profile-successfully-updated'
          # @reloadInfo(true)
          location.reload()
        , () =>
          @profileErrorMessage = 'app.errors.could-not-update-profile'
          @profileMessage = null

    @updatePassword = () =>
      @passwordErrorMessage = null
      @passwordMessage = null

      MyUser.one()
        .patch
          plainPassword: @passwordEntity.plainPassword
        .then (result) =>
          @passwordErrorMessage = null
          @passwordMessage = 'app.messages.password-successfully-changed'
          @reloadInfo(true)
        , () =>
          @passwordErrorMessage = 'app.errors.could-not-set-password'
          @passwordMessage = null

angular
  .module('mundoAuthentication')
  .controller 'UserAccountCtrl', UserAccountCtrl
